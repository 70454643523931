import React, {
    useContext,
    useRef,
    useState
} from 'react';
import {
    Link,
    useRouteMatch
} from "react-router-dom";
import {
    Container,
    Nav,
    Navbar as RBNavbar,
    NavDropdown
} from "react-bootstrap";
import useScrollPosition from "../hooks/ScrollHook";
import useOutsideAlerter from "../hooks/OutsideAlerter";
import JobsContext from "../context/JobsContext";

import werkenBijSmithLogo from "./../img/werkenbijsmith-logo.svg";

function NavbarLink({to, title, exact = false, hidden = false, onClick}) {
    const match = useRouteMatch({
        path: to,
        exact: exact
    });
    const className = "nav-item" + (match ? " active" : "") + (hidden ? " d-none" : "");
    return (
        <li className={ className } data-toggle="collapse" data-target=".navbar-collapse.show">
            <Link to={ to } className="nav-link" onClick={ onClick }>
                { title }
            </Link>
        </li>
    );
}

function DropdownLink({to, title, exact = false, hidden = false, onClick}) {
    const match = useRouteMatch({
        path: to,
        exact: exact
    });
    const className = "dropdown-item" + (match ? " active" : "") + (hidden ? " d-none" : "");
    return (
        <Link to={ to } className={ className } onClick={ onClick }>
            { title }
        </Link>
    );
}

function NavbarCompanyLinks(props) {
    return (
        <React.Fragment>
            <li className={ "nav-item" + (props.mobileOnly ? " nav-item-hidden-desktop-w" : " solar") }>
                <a className="nav-link" href="https://smithsolar.nl" onClick={ props.onClick }>
                    Smith Solar B.V.
                </a>
            </li>
            <li className={ "nav-item" + (props.mobileOnly ? " nav-item-hidden-desktop-w" : " tuinbouw") }>
                <a className="nav-link" href="https://smithtuinbouwtechniek.nl" onClick={ props.onClick }>
                    Smith Tuinbouwtechniek B.V.
                </a>
            </li>
            <li className={ "nav-item" + (props.mobileOnly ? " nav-item-hidden-desktop-w" : " active") }>
                <a className="nav-link" href="https://werkenbijsmith.nl" onClick={ props.onClick }>
                    Werken bij Smith
                </a>
            </li>
        </React.Fragment>
    );
}

function Navbar() {
    const jobsContext = useContext(JobsContext);

    const scrollPosition = useScrollPosition();
    const clamp = (value, min, max) => {
        return Math.min(Math.max(value, min), max);
    }

    let [showCollapsed, setShowCollapsed] = useState(false);
    let [showDropdown, setShowDropdown] = useState(false);

    const onNavigate = () => {
        setShowCollapsed(false);
        setShowDropdown(false);
    }

    const jobsDropdownRef = useRef(null);
    useOutsideAlerter(jobsDropdownRef, () => setShowDropdown(false));

    return (
        <React.Fragment>
            <nav className="navbar navbar-expand-lg navbar-expand navbar-light bg-light fixed-top navbar-second">
                <div className="container">
                    <div className="collapse navbar-collapse">
                        <ul className="navbar-nav mr-auto"/>
                        <ul className="navbar-nav">
                            <NavbarCompanyLinks/>
                        </ul>
                    </div>
                </div>
            </nav>

            <RBNavbar bg="light" expand="lg" className="fixed-top navbar-main" expanded={ showCollapsed }>
                <Container>
                    <a className="navbar-brand navbar-brand-desktop" href="/">
                        <img src={ werkenBijSmithLogo } alt="Werken bij Smith"
                             height={ clamp(100 - scrollPosition.y, 60, 100) + "px" }/>
                    </a>
                    <a className="navbar-brand navbar-brand-small" href="/">
                        <img src={ werkenBijSmithLogo } alt="Werken bij Smith" height="60px"/>
                    </a>
                    <RBNavbar.Toggle aria-controls="basic-navbar-nav" onClick={ () => setShowCollapsed(!showCollapsed) } />
                    <RBNavbar.Collapse id="basic-navbar-nav">
                        <Nav className="mr-auto"/>
                        <Nav>
                            <NavbarLink to="/" title="Home" exact onClick={ onNavigate }/>
                            <NavDropdown
                                ref={ jobsDropdownRef }
                                title="Vacatures"
                                active={ useRouteMatch({ path: "/vacature" })}
                                id="jobs"
                                show={ showDropdown }
                                onClick={ () => setShowDropdown(!showDropdown) }
                                alignRight
                            >
                                { jobsContext.accountManagerZonnepanelen && (
                                    <DropdownLink
                                        to="/vacature/account-manager-zonnepanelen"
                                        title="Hulp monteur Elektrotechniek"
                                        onClick={ onNavigate }
                                    />
                                )}
                                { jobsContext.administratiefMedewerkerInkoop && (
                                    <DropdownLink
                                        to="/vacature/administratief-medewerker-inkoop"
                                        title="Administratief medewerker inkoop"
                                        onClick={ onNavigate }
                                    />
                                )}
                                { jobsContext.afterSalesCoordinator && (
                                    <DropdownLink
                                        to="/vacature/after-sales-coordinator"
                                        title="After Sales Coördinator"
                                        onClick={ onNavigate }
                                    />
                                )}
                                { jobsContext.commercieelMedewerkerBinnendienst && (
                                    <DropdownLink
                                        to="/vacature/commercieel-medewerker-binnendienst"
                                        title="Commercieël medewerker binnendienst"
                                        onClick={ onNavigate }
                                    />
                                )}
                                { jobsContext.verkoopBinnendienst && (
                                    <DropdownLink
                                        to="/vacature/verkoop-binnendienst"
                                        title="Medewerker Verkoop Binnendienst"
                                        onClick={ onNavigate }
                                    />
                                )}
                                { jobsContext.verkoopBinnendienst24uur && (
                                    <DropdownLink
                                        to="/vacature/verkoop-binnendienst-24-uur"
                                        title="Medewerker Verkoop Binnendienst"
                                        onClick={ onNavigate }
                                    />
                                )}
                                { jobsContext.elektromonteur && (
                                    <DropdownLink
                                        to="/vacature/elektromonteur"
                                        title="Elektromonteur"
                                        onClick={ onNavigate }
                                    />
                                )}
                                { jobsContext.hulpMonteurElektrotechniek && (
                                    <DropdownLink
                                        to="/vacature/hulp-monteur-elektrotechniek"
                                        title="Hulp monteur Elektrotechniek"
                                        onClick={ onNavigate }
                                    />
                                )}
                                { jobsContext.magazijnMedewerker && (
                                    <DropdownLink
                                        to="/vacature/magazijnmedewerker"
                                        title="Geordende Magazijnmedewerker"
                                        onClick={ onNavigate }
                                    />
                                )}
                                { jobsContext.werkvoorbereiderElektromonteur && (
                                    <DropdownLink
                                        to="/vacature/werkvoorbereider-elektromonteur"
                                        title="Werkvoorbereider / Elektromonteur"
                                        onClick={ onNavigate }
                                    />
                                )}
                                { jobsContext.werkvoorbereiderPlanner && (
                                    <DropdownLink
                                        to="/vacature/werkvoorbereider-planner"
                                        title="Werkvoorbereider / Planner"
                                        onClick={ onNavigate }
                                    />
                                )}
                                { jobsContext.tekenaarWerkvoorbereiderInstallatietechniek && (
                                    <DropdownLink
                                        to="/vacature/tekenaar-werkvoorbereider-installatietechniek"
                                        title="Tekenaar / Werkvoorbereider Installatietechniek"
                                        onClick={ onNavigate }
                                    />
                                )}
                                { jobsContext.werkvoorbereider && (
                                    <DropdownLink
                                        to="/vacature/werkvoorbereider"
                                        title="Werkvoorbereider"
                                        onClick={ onNavigate }
                                    />
                                )}
                                { Object.values(jobsContext).find((value) => value) === undefined && (
                                    <p className="mx-3 my-2 text-muted">
                                        <i>Momenteel geen vacatures beschikbaar</i>
                                    </p>
                                )}
                            </NavDropdown>
                            <li className="nav-item nav-item-hidden-desktop-w"><hr/></li>
                            <NavbarCompanyLinks mobileOnly={ true } onClick={ onNavigate }/>
                        </Nav>
                    </RBNavbar.Collapse>
                </Container>
            </RBNavbar>

            <div className="navbar-page-spacer"/>
        </React.Fragment>
    );
}

export default React.memo(Navbar);
