import React from 'react';
import {
    Route,
    Switch
} from "react-router-dom";
import ScrollToTop from "./hooks/ScrollToTop";
import useTracking from "./hooks/useTracking";

import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import JobsContext from "./context/JobsContext";

import Home from "./pages/Home";
import Page404 from "./pages/Page404";
import Elektromonteur from "./pages/jobs/Elektromonteur";
import AccountManagerZonnepanelen from "./pages/jobs/AccountManagerZonnepanelen";
import VerkoopBinnendienst from "./pages/jobs/VerkoopBinnendienst";
import VerkoopBinnendienst24uur from "./pages/jobs/VerkoopBinnendienst24uur";
import HulpMonteurElektrotechniek from "./pages/jobs/HulpMonteurElektrotechniek";
import MagazijnMedewerker from "./pages/jobs/MagazijnMedewerker";
import TekenaarWerkvoorbereiderInstallatietechniek from "./pages/jobs/TekenaarWerkvoorbereiderInstallatietechniek";
import WerkvoorbereiderElektromonteur from "./pages/jobs/WerkvoorbereiderElektromonteur";
import WerkvoorbereiderPlanner from "./pages/jobs/WerkvoorbereiderPlanner";
import AfterSalesCoordinator from "./pages/jobs/AfterSalesCoordinator";
import AdministratiefMedewerkerInkoop from "./pages/jobs/AdministratiefMedewerkerInkoop";
import CommercieelMedewerkerBinnendienst from "./pages/jobs/CommercieelMedewerkerBinnendienst";
import Werkvoorbereider from "./pages/jobs/Werkvoorbereider";

import "./scss/style.scss";

function App() {
    useTracking("UA-65727447-11");
    const jobs = {
        accountManagerZonnepanelen: false,
        administratiefMedewerkerInkoop: false,
        afterSalesCoordinator: false,
        commercieelMedewerkerBinnendienst: true,
        verkoopBinnendienst: false,
        verkoopBinnendienst24uur: false,
        elektromonteur: true,
        hulpMonteurElektrotechniek: false,
        magazijnMedewerker: false,
        werkvoorbereiderElektromonteur: false,
        werkvoorbereiderPlanner: false,
        tekenaarWerkvoorbereiderInstallatietechniek: false,
        werkvoorbereider: true,
    }
    return (
        <JobsContext.Provider value={ jobs }>
            <div className="d-flex flex-column" style={{ minHeight: "100svh" }}>
                <div className="flex-grow-1">

                    <Navbar/>

                    <Switch>
                        <Route path="/" exact>
                            <ScrollToTop/>
                            <Home/>
                        </Route>
                        { jobs.accountManagerZonnepanelen && (
                            <Route path="/vacature/account-manager-zonnepanelen" exact>
                                <ScrollToTop/>
                                <AccountManagerZonnepanelen/>
                            </Route>
                        ) }
                        { jobs.administratiefMedewerkerInkoop && (
                            <Route path="/vacature/administratief-medewerker-inkoop" exact>
                                <ScrollToTop/>
                                <AdministratiefMedewerkerInkoop/>
                            </Route>
                        ) }
                        { jobs.afterSalesCoordinator && (
                            <Route path="/vacature/after-sales-coordinator" exact>
                                <ScrollToTop/>
                                <AfterSalesCoordinator/>
                            </Route>
                        ) }
                        { jobs.commercieelMedewerkerBinnendienst && (
                            <Route path="/vacature/commercieel-medewerker-binnendienst" exact>
                                <ScrollToTop/>
                                <CommercieelMedewerkerBinnendienst/>
                            </Route>
                        ) }
                        { jobs.verkoopBinnendienst && (
                            <Route path="/vacature/verkoop-binnendienst" exact>
                                <ScrollToTop/>
                                <VerkoopBinnendienst/>
                            </Route>
                        ) }
                        { jobs.verkoopBinnendienst24uur && (
                            <Route path="/vacature/verkoop-binnendienst-24-uur" exact>
                                <ScrollToTop/>
                                <VerkoopBinnendienst24uur/>
                            </Route>
                        ) }
                        { jobs.elektromonteur && (
                            <Route path="/vacature/elektromonteur" exact>
                                <ScrollToTop/>
                                <Elektromonteur/>
                            </Route>
                        ) }
                        { jobs.hulpMonteurElektrotechniek && (
                            <Route path="/vacature/hulp-monteur-elektrotechniek" exact>
                                <ScrollToTop/>
                                <HulpMonteurElektrotechniek/>
                            </Route>
                        ) }
                        { jobs.magazijnMedewerker && (
                            <Route path="/vacature/magazijnmedewerker" exact>
                                <ScrollToTop/>
                                <MagazijnMedewerker/>
                            </Route>
                        ) }
                        { jobs.werkvoorbereiderElektromonteur && (
                            <Route path="/vacature/werkvoorbereider-elektromonteur" exact>
                                <ScrollToTop/>
                                <WerkvoorbereiderElektromonteur/>
                            </Route>
                        ) }
                        { jobs.werkvoorbereiderPlanner && (
                            <Route path="/vacature/werkvoorbereider-planner" exact>
                                <ScrollToTop/>
                                <WerkvoorbereiderPlanner/>
                            </Route>
                        ) }
                        { jobs.tekenaarWerkvoorbereiderInstallatietechniek && (
                            <Route path="/vacature/tekenaar-werkvoorbereider-installatietechniek" exact>
                                <ScrollToTop/>
                                <TekenaarWerkvoorbereiderInstallatietechniek/>
                            </Route>
                        ) }
                        { jobs.werkvoorbereider && (
                            <Route path="/vacature/werkvoorbereider" exact>
                                <ScrollToTop/>
                                <Werkvoorbereider/>
                            </Route>
                        ) }

                        <Route path="/">
                            <ScrollToTop/>
                            <Page404/>
                        </Route>
                    </Switch>

                </div>
                <Footer/>
            </div>

        </JobsContext.Provider>
    );
}

export default App;
