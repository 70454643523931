import React from "react";
import Helmet from "react-helmet";

function Elektromonteur() {
    return (
        <React.Fragment>
            <Helmet>
                <title>Elektromonteur - Aanpakker zonder hoogtevrees - Vacature - Werken bij Smith</title>
                <meta name="description" content="In deze functie ligt de nadruk op de installatie van zonnepanelen. Dit zowel bij particulieren als bij zakelijke klanten. Het is aan jou om de reeds voorbereide werkzaamheden te vertalen naar goed werkende systemen." />
            </Helmet>

            <div className="jumbotron jumbotron-fluid jumbotron-header">
                <div className="container container-sm">
                    <h1>Vacature</h1>
                    <p>Elektromonteur - Aanpakker zonder hoogtevrees</p>
                </div>
            </div>

            <div className="container container-sm">

                <div className="card mb-3">
                    <div className="card-body text-center">
                        <b className="text-solar ml-2 mr-2">
                            <i className="fas fa-clock"/> Werkweek van 40 uur
                        </b>
                        <b className="text-tuinbouw ml-2 mr-2">
                            <i className="fas fa-graduation-cap"/> VMBO
                        </b>
                        <b className="text-solar ml-2 mr-2">
                            <i className="fas fa-building"/> Smith Solar B.V.
                        </b>
                        <b className="text-tuinbouw ml-2 mr-2">
                            <i className="fas fa-building"/> Smith Tuinbouwtechniek B.V.
                        </b>
                    </div>
                </div>

                <p>
                    Smith Solar en Smith Tuinbouwtechniek gaan hand in hand in onze werkzaamheden. Het gros van het werk
                    bestaat uit het installeren van zonnepanelen maar we nemen ook elektrotechnische projecten in de
                    tuinbouw aan.
                </p>
                <p>
                    In deze functie ligt de nadruk op de installatie van zonnepanelen. Dit zowel bij particulieren als
                    bij zakelijke klanten. Het is aan jou om de reeds voorbereide werkzaamheden te vertalen naar goed
                    werkende systemen.
                </p>

                <h2>Taken</h2>
                <ul>
                    <li>Het installeren van zonnepanelen bij zowel zakelijke als particuliere klanten.</li>
                    <li>Het incidentele uitvoeren van alle voorkomende elektrotechnische installatie- en servicewerkzaamheden aan elektrotechnische tuinbouwinstallaties, alsmede het installeren en inregelen van klimaatcomputers.</li>
                    <li>Eventuele andere werkzaamheden die door het bedrijf noodzakelijk worden geacht.</li>
                    <li>Werkzaamheden zullen verricht worden voor opdrachtgevers in heel Nederland, en buiten Nederland in overleg.</li>
                </ul>

                <h2>Wat wij vragen</h2>
                <p>
                    Je hebt bij voorkeur een jaar ervaring opgedaan in een vergelijkbare, elektrotechnische functie. Je
                    hebt veel belangstelling voor elektrotechniek en wil jezelf in hoog tempo verder ontwikkelen. Je
                    bent een enthousiaste techneut met een flexibele instelling. Je weet van aanpakken en bent op zoek
                    naar een leuke uitdaging met toekomstperspectief. Indien je aanvullende opleidingen hebt gevolgd,
                    dan is dat een pré.
                </p>
                <ul>
                    <li>Bereidheid tot het volgen van met de functie verband houdende cursussen.</li>
                    <li>Geen 9 tot 5 mentaliteit.</li>
                    <li>Functiespecifieke competenties: veilig gedrag, zorgvuldigheid/accuratesse, flexibiliteit/aanpassingsvermogen, doorzettingsvermogen/volharding.</li>
                </ul>

                <h2>Wat wij bieden</h2>
                <p>
                    Als je werkt is het wel zo prettig dat je het er naar je zin hebt. Bij Smith gaat dit als vanzelf,
                    een hipsterbar is er niks bij en dat gewoon in het nuchtere Westland. Tegelijkertijd vind je alle
                    gekheid hier alleen op stokjes en wordt er keihard gewerkt.
                </p>
                <p>
                    Onze organisatie groeit snel en we bieden je uitstekende mogelijkheden om je talenten te ontplooien.
                </p>

                <h2>Is je interesse gewekt en wil je meer weten?</h2>
                <p>
                    Stuur dan je motivatie met CV naar Karel Smith
                    via <a href="mailto:hr@smithtechniek.nl">hr@smithtechniek.nl</a> of
                    bel <a href="dial:+31620967847">06 209 678 47</a>.
                </p>

            </div>
        </React.Fragment>
    );
}

export default React.memo(Elektromonteur);
