import React from "react";
import Helmet from "react-helmet";

function HulpMonteurElektrotechniek() {
    return (
        <React.Fragment>
            <Helmet>
                <title>Hulp monteur Elektrotechniek - Vacature - Werken bij Smith</title>
                <meta name="description" content="Als Assistent Elektromonteur heb je een belangrijke, ondersteunende taak. Samen met de zelfstandig werkende Elektromonteurs verzorg je de volledige installatie van de elektrotechnische installaties die door ons worden ontwikkeld. Het is aan jou om de door ons ontwikkelde oplossingen te vertalen naar goed werkende systemen. Omdat je afwisselend werkt met zwakstroom, sterkstroom, klimaattechniek en beveiligingstechniek, doe je snel veel ervaring op: afwisseling is dus gegarandeerd!" />
            </Helmet>

            <div className="jumbotron jumbotron-fluid jumbotron-header">
                <div className="container container-sm">
                    <h1>Vacature</h1>
                    <p>Hulp monteur Elektrotechniek</p>
                </div>
            </div>

            <div className="container container-sm">

                <div className="card mb-3">
                    <div className="card-body text-center">
                        <b className="text-solar ml-2 mr-2">
                            <i className="fas fa-clock"/> Werkweek van 40 uur
                        </b>
                        <b className="text-tuinbouw ml-2 mr-2">
                            <i className="fas fa-graduation-cap"/> VMBO
                        </b>
                        <b className="text-solar ml-2 mr-2">
                            <i className="fas fa-building"/> Smith Solar B.V.
                        </b>
                        <b className="text-tuinbouw ml-2 mr-2">
                            <i className="fas fa-building"/> Smith Tuinbouwtechniek B.V.
                        </b>
                    </div>
                </div>

                <p>
                    Als <b className="text-highlight">Assistent Elektromonteur</b> heb je een belangrijke,
                    ondersteunende taak. Samen met de zelfstandig werkende Elektromonteurs verzorg je de volledige
                    installatie van de elektrotechnische installaties die door ons worden ontwikkeld. Het is aan jou om
                    de door ons ontwikkelde oplossingen te vertalen naar goed werkende systemen. Omdat je afwisselend
                    werkt met zwakstroom, sterkstroom, klimaattechniek en beveiligingstechniek, doe je snel veel
                    ervaring op: afwisseling is dus gegarandeerd!
                </p>
                <p>
                    <i>Je bent flexibel in tijd en staat ook open voor montage van zonnepanelen installaties
                    bij <b className="text-solar">Smith Solar B.V.</b></i>
                </p>

                <h2>Wat we van je vragen</h2>
                <p>
                    Na afronding van je VMBO-opleiding heb je bij voorkeur ongeveer een half jaar ervaring opgedaan in
                    een vergelijkbare, technische functie. Je hebt veel belangstelling voor elektrotechniek en wilt
                    jezelf in hoog tempo verder ontwikkelen. Je bent een enthousiaste techneut met een flexibele
                    instelling. Je weet van aanpakken en bent op zoek naar een leuke uitdaging met toekomstperspectief.
                    Indien je aanvullende opleidingen hebt gevolgd, dan strekt dat tot aanbeveling.
                </p>

                <ul>
                    <li>Bereidheid tot het volgen van met de functie verband houdende cursussen.</li>
                    <li>Geen 9 tot 5 mentaliteit.</li>
                    <li>
                        Functiespecifieke competenties: veilig gedrag, zorgvuldigheid/accuratesse,
                        flexibiliteit/aanpassingsvermogen, doorzettingsvermogen/volharding.
                    </li>
                </ul>

                <h2>Wat we je bieden</h2>
                <p>
                    Werken bij <b className="text-tuinbouw">Smith Tuinbouwtechniek B.V.</b> betekent werken binnen een
                    professionele en dynamische omgeving. Onze organisatie groeit snel en biedt uitstekende
                    mogelijkheden om je talenten te ontplooien. Bij <b className="text-tuinbouw">Smith Tuinbouwtechniek
                    B.V.</b>, een erkend leerbedrijf, vind je een werkgever die oog heeft voor jouw persoonlijke
                    ontwikkeling. Bovendien kun je rekenen op een prima pakket aan primaire en secundaire
                    arbeidsvoorwaarden.
                </p>

                <h2>Ben jij degene die we zoeken?</h2>
                <p>
                    Stuur dan je motivatie met CV naar Karel Smith
                    via <a href="mailto:hr@smithtechniek.nl">hr@smithtechniek.nl</a> of
                    bel <a href="dial:+31620967847">06 209 678 47</a>.
                </p>

            </div>
        </React.Fragment>
    );
}

export default React.memo(HulpMonteurElektrotechniek);
