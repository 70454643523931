import React from "react";
import Helmet from "react-helmet";

function CommercieelMedewerkerBinnendienst() {
    return (
        <React.Fragment>
            <Helmet>
                <title>Commercieël Medewerker Binnendienst - Vacature - Werken bij Smith</title>
                <meta name="description" content="Kan jij onze potentiële klanten overtuigen van ons product zodat ze daadwerkelijk klant worden? Ben jij 32-40 uur beschikbaar? Dan zijn wij op zoek naar jou!" />
            </Helmet>

            <div className="jumbotron jumbotron-fluid jumbotron-header">
                <div className="container container-sm">
                    <h1>Vacature</h1>
                    <p>Commercieël Medewerker Binnendienst</p>
                </div>
            </div>

            <div className="container container-sm">

                <div className="card mb-3">
                    <div className="card-body text-center">
                        <b className="text-tuinbouw ml-2 mr-2">
                            <i className="fa-solid fa-location-dot"/> Maasdijk
                        </b>
                        <b className="text-solar ml-2 mr-2">
                            <i className="fas fa-clock"/> Fulltime / Parttime
                        </b>
                        <b className="text-tuinbouw ml-2 mr-2">
                            <i className="fas fa-graduation-cap"/> MBO
                        </b>
                        <b className="text-solar ml-2 mr-2">
                            <i className="fas fa-building"/> Smith Solar B.V.
                        </b>
                    </div>
                </div>

                <p>
                    <b>Smith Solar</b> is op zoek naar een gedreven commercieel medewerker Binnendienst die een actieve rol
                    speelt in de groei van ons bedrijf. In deze functie ben jij verantwoordelijk voor het nabellen van leads,
                    het proactief benaderen van nieuwe klanten en het ondersteunen van onze accountmanagers in het
                    genereren van nieuwe zakelijke kansen binnen de zonne-energiesector. Ben jij communicatief sterk
                    en haal jij energie uit het leggen van contacten? Dan is deze functie iets voor jou!
                </p>

                <h2>Wat ga je doen?</h2>
                <ul>
                    <li>
                        Actief nabellen van leads en het in kaart brengen van nieuwe zakelijke kansen.
                    </li>
                    <li>
                        Het onderhouden en uitbreiden van het klantenbestand door telefonische acquisitie.
                    </li>
                    <li>
                        Ondersteunen van accountmanagers in het realiseren van commerciële doelstellingen.
                    </li>
                    <li>
                        Het opvolgen van offertes en zorgen voor een tijdige en professionele afhandeling van klantvragen.
                    </li>
                    <li>
                        Het inbrengen van feedback van klanten en prospects om onze dienstverlening te verbeteren.
                    </li>
                </ul>

                <h2>Wat verwachten wij van jou?</h2>
                <ul>
                    <li>
                        Je hebt een afgeronde MBO-opleiding, bij voorkeur in een commerciële richting.
                    </li>
                    <li>
                        Je hebt aantoonbare ervaring in telefonische acquisitie of sales.
                    </li>
                    <li>
                        Je hebt een proactieve en klantgerichte houding en gaat voor het behalen van commerciële targets.
                    </li>
                    <li>
                        Je kunt goed zelfstandig werken, maar weet ook wanneer je als teamspeler moet acteren.
                    </li>
                    <li>
                        Je hebt goede communicatieve vaardigheden in zowel Nederlands als Engels.
                    </li>
                    <li>
                        Je hebt ervaring met CRM-systemen en Microsoft Office.
                    </li>
                </ul>

                <h2>Wat bieden wij jou?</h2>
                <ul>
                    <li>
                        Een uitdagende functie binnen een dynamische en snelgroeiende organisatie.
                    </li>
                    <li>
                        Een marktconform salaris, afhankelijk van ervaring en prestaties.
                    </li>
                    <li>
                        Mogelijkheden voor persoonlijke ontwikkeling.
                    </li>
                    <li>
                        Een gezellig en enthousiast team, waar samenwerking en resultaat centraal staan.
                    </li>
                    <li>
                        Goede secundaire arbeidsvoorwaarden zoals vakantiedagen en pensioenregeling.
                    </li>
                </ul>

                <h2>Interesse?</h2>
                <p>
                    Ben jij die commerciële en gedreven Medewerker Binnendienst Acquisitie die wij zoeken? Stuur dan
                    je motivatiebrief en CV naar <a href="mailto:hr@smithtechniek.nl">hr@smithtechniek.nl</a> en we
                    nemen snel contact met je op!
                </p>
                <p>
                    Smith Solar kijkt uit naar je sollicitatie!
                </p>

            </div>
        </React.Fragment>
    );
}

export default React.memo(CommercieelMedewerkerBinnendienst);
