import React from "react";
import Helmet from "react-helmet";

function VerkoopBinnendienst24uur() {
    return (
        <React.Fragment>
            <Helmet>
                <title>Medewerker Verkoop Binnendienst - Vacature - Werken bij Smith</title>
                <meta name="description" content="Ter versterking van ons team op kantoor zijn wij op zoek naar een commerciële tijger met haar op de tanden. Kan jij op z’n Westlands onze potentiële klanten overtuigen van ons product zodat ze daadwerkelijk klant worden? Dan zijn wij op zoek naar jou!" />
            </Helmet>

            <div className="jumbotron jumbotron-fluid jumbotron-header">
                <div className="container container-sm">
                    <h1>Vacature</h1>
                    <p>Commerciële tijger - Medewerker Verkoop Binnendienst</p>
                </div>
            </div>

            <div className="container container-sm">

                <div className="card mb-3">
                    <div className="card-body text-center">
                        <b className="text-solar ml-2 mr-2">
                            <i className="fas fa-clock"/> Werkweek van minimaal 24 uur
                        </b>
                        <b className="text-tuinbouw ml-2 mr-2">
                            <i className="fas fa-graduation-cap"/> MBO
                        </b>
                        <b className="text-solar ml-2 mr-2">
                            <i className="fas fa-building"/> Smith Solar B.V.
                        </b>
                    </div>
                </div>

                <p>
                    Ter versterking van ons team op kantoor zijn wij op zoek naar een <b>commerciële tijger met haar op de
                    tanden</b>. Kan jij op z’n Westlands onze potentiële klanten overtuigen van ons product zodat ze
                    daadwerkelijk klant worden? Ben jij minimaal 24 uur beschikbaar? Dan zijn wij op zoek naar jou!
                </p>

                <h2>Haar op je tanden</h2>
                <p>
                    Officieel heet deze functie ‘Medewerker Verkoop Binnendienst’. In de praktijk willen we dat je
                    proactief aan het pionieren slaat. Alle binnengekomen leads moeten voorbereid en gebeld worden.
                    Eenvoudige daken bereid je zelf voor, als het wat ingewikkelder wordt plan je een afspraak in zodat
                    je buitendienst collega’s van Sales daar langs kunnen gaan.
                </p>
                <p>
                    Jij hebt van nature een commercieel inzicht en draait je hand niet om voor koude acquisitie. De
                    verkoopsignalen van potentiële klanten voel je feilloos aan en je voelt je ook nog eens verbonden
                    met het Westland.
                </p>
                <p>
                    Je werkt nauw samen met het gehele sales team en je ondersteunt bij alle commercieel gerelateerde
                    werkzaamheden. Door jouw inzet kan de rest van het Sales team optimaal verkoop, maar het liefste
                    streef je ze voorbij met het aantal leads dat je verkocht krijgt.
                </p>
                <p>
                    Alle leads komen binnen in ons CRM, geen kladjes op je bureau, maar via ons digitale systeem heb je
                    compleet overzicht. Dat werkt echt top.
                </p>
                <p>
                    De diverse commerciële taken zorgen ervoor dat je een hele afwisselende baan hebt waarbij je te
                    maken krijgt met diverse disciplines en afdelingen binnen de organisatie.
                </p>

                <h2>Heel concreet</h2>
                <ul>
                    <li>Ondersteuning in de voorbereiding van acquisities</li>
                    <li>Verwerken en behandeling van verkoopleads</li>
                    <li>Benaderen van prospects en maken afspraken verkoopadviseur naar aanleiding van leads</li>
                    <li>Voorbereiden en maken van offertes en aanpassen indien gewenst</li>
                    <li>In contact blijven met klanten met een ontvangen offertes</li>
                    <li>Signaleren van (extra) wensen van klanten waar aanvulling aangegeven kan worden</li>
                    <li>Bewaken van layout van offertes en calculatiemodel</li>
                    <li>Up-to-date houden van diverse sales gerelateerde documenten</li>
                    <li>Klant contacten na montage (indien nodig) met als doel: "een tevreden klant"</li>
                </ul>

                <h2>All we ask of you</h2>
                <ul>
                    <li>Gepokt en gemazeld in Excel</li>
                    <li>Commercieel gedreven</li>
                    <li>De Westlandse taal machtig</li>
                </ul>

                <h2>Kom er bij!</h2>
                <p>
                    Als je werkt is het wel zo prettig dat je het er naar je zin hebt. Bij Smith gaat dit als vanzelf,
                    een hipsterbar is er niks bij en dat gewoon in het nuchtere Westland. Tegelijkertijd vind je alle
                    gekheid hier alleen op stokjes en wordt er keihard gewerkt.
                </p>
                <p>
                    Onze organisatie groeit snel en we bieden je uitstekende mogelijkheden om je talenten te ontplooien.
                </p>

                <h2>Is je interesse gewekt en wil je meer weten?</h2>
                <p>
                    Stuur dan je motivatie met CV naar Karel Smith
                    via <a href="mailto:hr@smithtechniek.nl">hr@smithtechniek.nl</a> of
                    bel <a href="dial:+31620967847">06 209 678 47</a>.
                </p>

            </div>
        </React.Fragment>
    );
}

export default React.memo(VerkoopBinnendienst24uur);
