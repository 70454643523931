import React from "react";
import Helmet from "react-helmet";

function Werkvoorbereider() {
    return (
        <React.Fragment>
            <Helmet>
                <title>Werkvoorbereider - Vacature - Werken bij Smith</title>
                <meta name="description" content="Ben jij gestructureerd en hou je van een puzzel als het gaat om het uitzoeken van materialen en voorraden beheren? Ga dan aan de slag binnen deze veelzijdige rol als Werkvoorbereider / Planner bij Smith Solar B.V." />
            </Helmet>

            <div className="jumbotron jumbotron-fluid jumbotron-header">
                <div className="container container-sm">
                    <h1>Vacature</h1>
                    <p>Werkvoorbereider</p>
                </div>
            </div>

            <div className="container container-sm">

                <div className="card mb-3">
                    <div className="card-body text-center">
                        <b className="text-tuinbouw ml-2 mr-2">
                            <i className="fa-solid fa-location-dot"/> Maasdijk
                        </b>
                        <b className="text-solar ml-2 mr-2">
                            <i className="fas fa-clock"/> Werkweek van 40 uur
                        </b>
                        <b className="text-tuinbouw ml-2 mr-2">
                            <i className="fa-solid fa-coins"/> Conform ervaring
                        </b>
                        <b className="text-solar ml-2 mr-2">
                            <i className="fas fa-building"/> Smith Solar B.V.
                        </b>
                    </div>
                </div>

                <p>
                    <b>Smith Solar</b> is een snelgroeiend bedrijf in de duurzame energiebranche, gespecialiseerd in het
                    installeren en onderhouden van zonnepanelen, laadpalen voor elektrische voertuigen en thuisaccu’s.
                    Wij leveren niet alleen innovatieve producten, maar ook betrouwbare service en maatwerk voor onze
                    klanten. Om ons team te versterken, zijn we op zoek naar een gemotiveerde en ervaren{" "}
                    <b>Werkvoorbereider</b>.
                </p>

                <h2>Wat ga je doen?</h2>
                <p>
                    Als Werkvoorbereider ben je verantwoordelijk voor het volledige voorbereidingsproces van onze
                    projecten, van technische tekeningen tot de logistieke planning. Je zorgt ervoor dat alle benodigde
                    materialen en middelen tijdig beschikbaar zijn en dat de uitvoering van de projecten soepel
                    verloopt.
                    Je werkt nauw samen met projectleiders, monteurs en leveranciers om projecten op tijd en binnen
                    het budget te realiseren.
                </p>


                <h2>Taken en verantwoordelijkheden</h2>

                <ul>
                    <li>
                        Het voorbereiden en coördineren van zonnepanelen-, laadpalen- en thuisaccu-projecten.
                    </li>
                    <li>
                        Opstellen van gedetailleerde werkplannen, tekeningen en technische documentatie.
                    </li>
                    <li>
                        Bestellen en beheren van materialen, gereedschappen en apparatuur.
                        Contact onderhouden met leveranciers en onderaannemers om de levering van materialen te
                        waarborgen.
                    </li>
                    <li>
                        Zorgen voor de juiste planning en voortgang van de projecten.
                    </li>
                    <li>
                        Het controleren van projecttekeningen en specificaties voor uitvoering.
                    </li>
                    <li>
                        Het ondersteunen van de projectleider bij het bewaken van de kosten en kwaliteit.
                    </li>
                    <li>
                        Het coördineren van de werkzaamheden met het uitvoerende team van monteurs.
                    </li>
                </ul>

                <h2>Wat breng je mee?</h2>

                <ul>
                    <li>
                        Een afgeronde technische mbo- of hbo-opleiding (bij voorkeur richting elektrotechniek,
                        werktuigbouwkunde of vergelijkbaar).
                    </li>
                    <li>
                        Minimaal 2 jaar ervaring in een vergelijkbare functie, bij voorkeur in de zonne-energiesector.
                    </li>
                    <li>
                        Kennis van en ervaring met zonnepanelen, laadpalen en thuisaccu’s is een pre.
                    </li>
                    <li>
                        Sterk organisatorisch vermogen en goed in het plannen en coördineren van werkzaamheden.
                    </li>
                    <li>
                        Communicatief vaardig en in staat om zowel met interne teams als externe partners effectief
                        samen te werken.
                    </li>
                    <li>
                        Proactieve houding en oog voor detail.
                    </li>
                    <li>
                        Goede beheersing van de Nederlandse en Engelse taal, zowel mondeling als schriftelijk.
                    </li>
                </ul>

                <h2>Wat bieden wij?</h2>
                <ul>
                    <li>
                        Een uitdagende functie binnen een dynamisch en snelgroeiend bedrijf.
                    </li>
                    <li>
                        Ruimte voor persoonlijke ontwikkeling en groei binnen het bedrijf.
                    </li>
                    <li>
                        Een collegiale en informele werksfeer.
                    </li>
                    <li>
                        Marktconform salaris en goede secundaire arbeidsvoorwaarden.
                    </li>
                    <li>
                        Mogelijkheid om een bijdrage te leveren aan de verduurzaming van Nederland.
                    </li>
                </ul>

                <p>
                    Heb je interesse in deze functie en wil je samen met ons werken aan de toekomst van duurzame
                    energie? Stuur dan je motivatie en CV naar <a href="mailto:hr@smithtechniek.nl">hr@smithtechniek.nl</a>.
                </p>

                <p>
                    <b>Smith Solar</b> kijkt uit naar jouw sollicitatie!
                </p>

            </div>
        </React.Fragment>
    );
}

export default React.memo(Werkvoorbereider);
